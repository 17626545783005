.icon {
    font-size: 1.55rem;
}

.btn .icon {
    font-size: 1rem;
}

.normalicon {
    font-size: var(--bs-body-font-size) !important;
}