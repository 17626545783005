.pcp-menu {
    z-index:100 !important;
}
.pcp-menu, .pcp-form {
    transition: all 1s;
}

    .pcp-menu .icon {
        font-size: 1.2rem;
    }

    .pcp-menu.closed .nav-item {
        text-align: center;
        width: 100%;
    }

    .pcp-menu .nav-link {
        --bs-nav-link-padding-y: 0.3rem;
    }

    .pcp-menu.closed .card-body {
        padding: 0px;
    }

    .pcp-menu .pcp-menu-icon {
        margin-right: 5px;
        font-size: 1rem;
    }

    .pcp-menu.closed .pcp-menu-icon {
        font-size: 1.25rem;
        margin-right: 0px;
    }

    .pcp-menu.closed .pcp-menu-text {
        display: none;
    }

    .pcp-menu.closed nav-link {
        padding-top: 0px;
        padding-bottom: 0px;
    }
