.home {
    font-family: "Font-Bold";
    width:60%
}

.home h5,
.home h1,
.home h4,
.home h3,
.home h2,
.home h6 {
    line-height: 33px;
}

.home h1 {
    font-family: "Font-Bold1" !important;
    letter-spacing: 0.06em;
    text-transform: uppercase;
}

.home .color-blue {
    color: #0E238A;
}

.home .color-black {
    color: #000;
}

.home button {
    background-color: #0E238A !important;
    border-color: #0E238A !important;
    padding-left: 50px !important;
    padding-right: 50px !important;
    padding-top: 15px !important;
    padding-bottom: 15px !important;
    border-radius: 30px !important;
    text-transform: uppercase;
}

.home-img-container {
    position: relative;
    /* border-radius: 50%;
    box-shadow: 0px 0px 5px #ced7ff, 52px -72px 5px #ced7ff; */
}

.home-img-shadow {}

.home-img {
    position: relative;
    /* border-radius: 50%; */
    /*z-index: 5;*/
   /* width: 65%;*/
    /* box-shadow: 4px -15px #ced7ff; */
}

.home-img-shadow {
    position: relative;
    display: inline-block;
}

.home-img-shadow::before {
    /* content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    margin: auto;
    top: -55px;
    left: 10px;
    right: 0px;
    background: #ced7ff;
    border-radius: 50%;
    display: inline-block;
    z-index: 0; */
    /* content: "";
    width: 30px;
    height: 30px;
    background: red url("http://www.gentleface.com/i/free_toolbar_icons_16x16_black.png") no-repeat -30px -50px fixed;
    top: 10px;
    right: 5px;
    position: absolute;
    display: inline-block; */
}


/* 
.home-img:after {
    content: "";
    height: 100px;
    width: 200px;
    background: red;
    border-radius: 50%;
    position: absolute;
} */

.home .icon.phone svg {
    transform: rotate(104.43deg);
}