.multi-range-slider * {
    transition: width 0.1s;
}

.multi-range-slider .slider {
    position: relative;
    width: 100%;
}

.multi-range-slider .slider__track,
.multi-range-slider .slider__range,
.multi-range-slider .slider__left-value,
.multi-range-slider .slider__right-value {
    position: absolute;
}

.multi-range-slider .slider__track,
.multi-range-slider .slider__range {
    border-radius: 3px;
    height: 5px;
}

.multi-range-slider .slider__track {
    background-color: var(--tagsinput-bg);
    width: 100%;
    z-index: 1;
}

.multi-range-slider .slider__range {
    background-color: var(--theme1);
    z-index: 2;
}

.multi-range-slider .slider__left-value,
.multi-range-slider .slider__right-value {
    color: var(--placehoder-color);
    font-size: 12px;
    margin-top: 20px;
}

.multi-range-slider .slider__left-value {
    left: 6px;
}

.multi-range-slider .slider__right-value {
    right: -4px;
}


/* Removing the default appearance */

.multi-range-slider .thumb,
.multi-range-slider .thumb::-webkit-slider-thumb {
    -webkit-appearance: none;
    -webkit-tap-highlight-color: transparent;
}

.multi-range-slider .thumb {
    pointer-events: none;
    position: absolute;
    height: 0;
    width: 100%;
    outline: none;
}

.multi-range-slider .thumb--left {
    z-index: 3;
}

.multi-range-slider .thumb--right {
    z-index: 4;
}


/* For Chrome browsers */

.multi-range-slider .thumb::-webkit-slider-thumb {
    background-color: #f1f5f7;
    border: none;
    border-radius: 50%;
    box-shadow: 0 0 1px 1px #ced4da;
    cursor: pointer;
    height: 18px;
    width: 18px;
    margin-top: 4px;
    pointer-events: all;
    position: relative;
    border: 3px solid var(--theme1);
}


/* For Firefox browsers */

.multi-range-slider .thumb::-moz-range-thumb {
    background-color: #fff;
    border: none;
    border-radius: 50%;
    box-shadow: 0 0 1px 1px #fff;
    cursor: pointer;
    height: 18px;
    width: 18px;
    margin-top: 4px;
    pointer-events: all;
    position: relative;
}