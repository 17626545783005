.logged-in-user .profile-icon .icon {
    font-size: 4rem;
}

.logged-in-user hr {
    border-style: dashed !important;
    border-color: black;
}

.logged-in-user .fw-light {
    color: var(--form-heading)
}

.logged-in-user-info {
    background: var(--logged-in-user-info-bg);
}

.logged-in-user .theme-radio {
    padding-left: 0px;
    position: relative;
}

.logged-in-user .theme-radio input {
    display: none;
}

.logged-in-user .theme-radio input:checked~label::after {
    position: absolute;
    height: 15px;
    width: 10px;
    border-bottom: 3px solid red;
    border-right: 3px solid red;
    content: "";
    transform: rotate(45deg);
    left: 0;
    top: -10px;
    right: 0;
    bottom: 0;
    margin: auto;
}