.photo-cropper {
    position: relative;
}

.photo-cropper-choose {
    cursor: pointer;
}

.photo-cropper-choose-container {
    background: var(--bs-gray-200);
    line-height: 20px !important;
}

.photo-cropper-uploading {
    position: absolute;
    margin: auto;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

.photo-cropper-options {
    line-height: 10px;
}
