.home {
    font-family: "Font-Bold";
    width: 60%
}

    .home h5,
    .home h1,
    .home h4,
    .home h3,
    .home h2,
    .home h6 {
        line-height: 33px;
    }

    .home h1 {
        font-family: "Font-Bold1" !important;
        letter-spacing: 0.06em;
        text-transform: uppercase;
        font-size: 60px;
    }

    .home .color-blue {
        color: #0E238A;
    }

    .home .color-black {
        color: #000;
    }

    .home button {
        background-color: #0E238A !important;
        border-color: #0E238A !important;
        padding-left: 50px !important;
        padding-right: 50px !important;
        padding-top: 15px !important;
        padding-bottom: 15px !important;
        border-radius: 30px !important;
        text-transform: uppercase;
    }

.home-img-container {
    position: relative;
}

.home-img-shadow {
}

.home-img {
    position: relative;
}

.home-img-shadow {
    position: relative;
    display: inline-block;
}

.home .icon.phone svg {
    transform: rotate(104.43deg);
}
