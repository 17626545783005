
.profile-callout {
    position: relative;
    display: inline-block;
    background: rgb(103, 78, 167);
    border-radius: 50%;
    width: 210px;
    height: 210px;
    z-index: 999;
    padding: 5px;
}
.profile-callout textarea {
    border-radius: 50%;
    padding: 20px;
    padding-top: 32px;
    padding-bottom: 32px;
    font-size: 11px;
    resize: none;
    text-align: center;
    align-items: center;
}
.profile-callout::after {
    content: "";
    position: absolute;
    width: 30px;
    height: 30px;
    border: 3px solid rgb(103, 78, 167);
    transform: rotate(50deg);
    border-radius: 50%;
}
.profile-callout::before {
    content: "";
    position: absolute;
    width: 20px;
    height: 20px;
    border: 3px solid rgb(103, 78, 167);
    transform: rotate(50deg);
    border-radius: 50%;
}
.profile-callout.right.bottom::after {
    right: 0px;
    bottom: 10px;
}
.profile-callout.right.bottom::before {
    right: -18px;
    bottom: -9px;
}
.profile-callout.right.top::after {
    right: 0px;
    top: 10px;
}
.profile-callout.right.top::before {
    right: -18px;
    top: -9px;
}
.profile-callout.left.bottom::after {
    left: 0px;
    bottom: 10px;
}
.profile-callout.left.bottom::before {
    left: -18px;
    bottom: -9px;
}
.profile-callout.left.top::after {
    left: 0px;
    top: 10px;
}
.profile-callout.left.top::before {
    left: -18px;
    top: -9px;
}
