
.sp-floating {
    position: fixed;
    margin: auto;
    left: 10%;
    right: 15px;
    bottom: 38px;
    transition: all 1s;
}

.pcp-form-open .sp-floating {
    left: 26%;
}
