.circleofsupport-relative-wrapper {
    font-size: 12px;
    line-height: 13px;
    background: var(--bs-gray-200);
    line-height: 20px !important;
    border-radius: 50%;
    width: 160px;
    height: 160px;
    background: var(--circleOfSupportTextAreaBG1Color);
    border: 2px solid var(--circleOfSupportTextAreaBorder1Color);
}

.circleofsupport-relative-inner {
    line-height: 14px;
    border-radius: 50%;
    width: 150px;
    height: 150px;
    background: var(--circleOfSupportTextAreaBG2Color);
    border: 2px solid var(--circleOfSupportTextAreaBorder2Color);
}

.circleofsupport-relative-wrapper-choose {
    cursor: pointer;
}
.circleofsupport-relative-item-name, .circleofsupport-relative-item-relative {
    font-size: 12px;
}

.circleofsupport-relative-item-name {
    color: var(--circleOfSupportTextAreaNameColor)
}

.circleofsupport-relative-item-relative {
    color: var(--circleOfSupportTextAreaRelationshipColor)
}
