.sp-unit {
    width: 60px;
    font-size: 0.8rem !important;
}

.sp-curreny-input {
    width: 135px;
    font-size: 0.8rem !important;
}
.sp-curreny-input-unit {
    width: 65px;
    font-size: 0.8rem !important;
}
.sp-curreny-inputsmall{
    width: 80px !important;
    font-size: 0.8rem !important;
}

.form-control, .form-select {
    font-size: .8rem !important;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}
