.sk-menu {
    width: 100%;
    height: 56px;
    animation: skeleton 1s ease infinite !important;
}

.sk-menu .sk-menu-brand {
    width: 20%;
    height: 100%;
    background: var(--sk-bg);
    display: inline-block;
    margin-right: 10px;
}

.sk-menu .sk-menu-item {
    width: 100px;
    height: 100%;
    background: var(--sk-bg);
    margin-right: 10px;
    display: inline-block;
}

.sk-menu .sk-menu-item.right {
    float: right;
}

@media only screen and (max-width: 991px) {
    .sk-menu .sk-menu-item {
        display: none;
    }
}

@media only screen and (min-width: 1200px) {
    .sk-menu .sk-menu-item {
        width: 150px;
    }
}