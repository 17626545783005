.sk {
    animation: skeleton 1s ease infinite !important;
}

body {
    background: #0a4081;
    font-size: 90%;
}

@keyframes skeleton {
    to {
        opacity: 0.5;
    }
}

.form-heading {
    color: white;
}

.navbar-collapse {
    align-self: flex-end;
}

.nav-tabs {
    border: none;
    /*flex-wrap: nowrap;
    overflow: auto;
    height: 50px;*/
}

    .nav-tabs:hover {
        /*overflow: auto;*/
    }

    .nav-tabs .nav-item {
        white-space: nowrap;
    }

    .nav-tabs .nav-link {
        border: none;
        border-bottom: var(--bs-nav-tabs-border-width) solid var(--bs-nav-tabs-border-color);
    }

        .nav-tabs .nav-link.active {
            border: none;
            border-bottom: var(--bs-nav-tabs-border-width) solid var(--bs-nav-tabs-link-active-border-color);
        }

::-webkit-scrollbar-track {
    display: none !important;
}

::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    background-color: transparent;
}

::-webkit-scrollbar-thumb {
    background: #bbb !important;
    border-radius: 8px;
}

:root {
    --themeColor: #674ea7;
    --pageHeaderBGColor: #0355af;
    --pageHeaderTextColor: #ffffff;
    --pageFooterBGColor: #ffffff;
    --pageFooterTextColor: #000000;
    --titleTextColor: #674ea7;
    --subTitleTextColor: #0355af;
    --headingColor: #674ea7;
    --subheadingColor: #674ea7;
    --textColor: #000000;
    --circleOfSupportBorderColor: #5c5b52;
    --circleOfSupportBGColor: #fed966;
    --circleOfSupportTextAreaBG1Color: #ffbd9c;
    --circleOfSupportTextAreaBG2Color: #fbe5d7;
    --circleOfSupportTextAreaBorder1Color: #86430e;
    --circleOfSupportTextAreaBorder2Color: #ff0000;
    --circleOfSupportTextAreaNameColor: #9f1e23;
    --circleOfSupportTextAreaRelationshipColor: #4b9f89;
    --tagsinput-bg: #C5F5FF;
    --tagsinput-color: #0EB1D2;
}

.react-tagsinput * {
    font-family: 'Font-Regular', sans-serif !important;
}

.react-tagsinput-tag {
    color: var(--tagsinput-color) !important;
    background: var(--tagsinput-bg) !important;
    letter-spacing: 0.03em !important;
    border: 1px solid var(--tagsinput-color) !important;
}

.react-tagsinput--focused {
    border-color: var(--tagsinput-color) !important;
}

.rbt-menu > .dropdown-item {
    white-space: break-spaces;

}
.hidecontrol {
    visibility:hidden;
}

.react-datepicker__year-dropdown .react-datepicker__year-option:first-child::before {
    content: "\25B2";
    position: absolute;
    top: 5%;
    left: 45%;
    margin: auto;
}

.react-datepicker__year-dropdown::after {
    content: "\25BC";
    display: block;
    position: relative;
    bottom: 15%;
}

.offline {
    color: red;
    animation: blinker 1s linear infinite;
    font-family: 'Font-Regular', sans-serif !important;    
}
@keyframes blinker {
    50% {
        opacity: 0;
    }
}
th {
    cursor: pointer !important;
}

.table > :not(caption) > * > * {
     padding: 0.1rem 0.1rem  !important;
 }