.currency-label::before {
    content: '$';
    font-size: .8rem !important;
}

.currency-label {
    /* letter-spacing: 0.8px; */
    /*font-size:15px !important;*/
    font-size: .8rem !important;
}
.fs-6 {
    font-size: .8rem !important;
}