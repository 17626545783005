.confirm-message .modal-body {
    text-align: center;
}

.confirm-message-icon .icon {
    font-size: 4rem;
    color: var(--confirm-message-icon-color);
}

.confirm-message-message {
    font-size: 1.25rem;
    color: var(--confirm-message-text-color);
}

.confirm-message .form-check {
    padding-left: 0rem;
    font-size: 1.25rem;
    color: #5B5B5B;
}