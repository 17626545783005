.data-grid [align=center] {
    text-align: center;
}

.data-grid [align=center] {
    text-align: center;
}

.data-grid [align=center] {
    text-align: center;
}

.data-grid-pagniation .icon {
    font-size: var(--bs-body-font-size) !important;
}

.selected-rows-summary .icon {
    font-size: var(--bs-body-font-size) !important;
    cursor: pointer;
}

[data-enable-double-click=true] tr,
[data-enable-double-click=true] .data-grid-content-row {
    cursor: pointer;
}


/* 
.data-grid .table-content {
    width: 100%;
}

.data-grid .filter-section {
    width: 0px;
    visibility: hidden;
    height: 0px;
}

.data-grid.filter-open .filter-section {
    width: 15%;
    height: auto;
}

.data-grid.filter-open .table-content {
    width: 85%;
} */

.data-grid-filter-secton.closed {
    width: 0px !important;
    visibility: hidden;
}

.data-grid-filter-secton {
    background: var(--theme3);
}