.circleofsupport-wrapper-outer {
  width: 650px;
  height: 650px;
  border: 3px solid var(--themeColor);
  border-radius: 51%;
  position: relative;
  background: var(--circleOfSupportBGColor);
  border: 2px solid var(--circleOfSupportBorderColor);
  margin: auto;
}

.circleofsupport-wrapper-outer .circle-big {
  z-index: 1000;
}

.circleofsupport-wrapper {
  width: 501px;
  height: 501px;
  border-radius: 51%;
  position: absolute;
  margin: auto;
  top: 20px;
  right: 0;
  left: 0;
}

.circleofsupport-wrapper .circle, .circleofsupport-wrapper .circle.callout {
  display: block;
  position: absolute;
  top: 51%;
  left: 51%;
  margin-left: -51px;
  border-radius: 51%;
  text-align: center;
  line-height: 100px;
}

.circleofsupport-wrapper .circle.small {
  width: 100px;
  height: 100px;
}

.circle-big {
  width: 250px;
  height: 250px;
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.circleofsupport-wrapper .circle.callout {
  width: 160px;
  height: 160px;
}

.circleofsupport-wrapper .deg-0 {
  transform: translate(251px);
}

.circleofsupport-wrapper .deg-1 {
  transform: rotate(1deg) translate(251px) rotate(-1deg);
}

.circleofsupport-wrapper .deg-2 {
  transform: rotate(2deg) translate(251px) rotate(-2deg);
}

.circleofsupport-wrapper .deg-3 {
  transform: rotate(3deg) translate(251px) rotate(-3deg);
}

.circleofsupport-wrapper .deg-4 {
  transform: rotate(4deg) translate(251px) rotate(-4deg);
}

.circleofsupport-wrapper .deg-5 {
  transform: rotate(5deg) translate(251px) rotate(-5deg);
}

.circleofsupport-wrapper .deg-6 {
  transform: rotate(6deg) translate(251px) rotate(-6deg);
}

.circleofsupport-wrapper .deg-7 {
  transform: rotate(7deg) translate(251px) rotate(-7deg);
}

.circleofsupport-wrapper .deg-8 {
  transform: rotate(8deg) translate(251px) rotate(-8deg);
}

.circleofsupport-wrapper .deg-9 {
  transform: rotate(9deg) translate(251px) rotate(-9deg);
}

.circleofsupport-wrapper .deg-10 {
  transform: rotate(10deg) translate(251px) rotate(-10deg);
}

.circleofsupport-wrapper .deg-11 {
  transform: rotate(11deg) translate(251px) rotate(-11deg);
}

.circleofsupport-wrapper .deg-12 {
  transform: rotate(12deg) translate(251px) rotate(-12deg);
}

.circleofsupport-wrapper .deg-13 {
  transform: rotate(13deg) translate(251px) rotate(-13deg);
}

.circleofsupport-wrapper .deg-14 {
  transform: rotate(14deg) translate(251px) rotate(-14deg);
}

.circleofsupport-wrapper .deg-15 {
  transform: rotate(15deg) translate(251px) rotate(-15deg);
}

.circleofsupport-wrapper .deg-16 {
  transform: rotate(16deg) translate(251px) rotate(-16deg);
}

.circleofsupport-wrapper .deg-17 {
  transform: rotate(17deg) translate(251px) rotate(-17deg);
}

.circleofsupport-wrapper .deg-18 {
  transform: rotate(18deg) translate(251px) rotate(-18deg);
}

.circleofsupport-wrapper .deg-19 {
  transform: rotate(19deg) translate(251px) rotate(-19deg);
}

.circleofsupport-wrapper .deg-20 {
  transform: rotate(20deg) translate(251px) rotate(-20deg);
}

.circleofsupport-wrapper .deg-21 {
  transform: rotate(21deg) translate(251px) rotate(-21deg);
}

.circleofsupport-wrapper .deg-22 {
  transform: rotate(22deg) translate(251px) rotate(-22deg);
}

.circleofsupport-wrapper .deg-23 {
  transform: rotate(23deg) translate(251px) rotate(-23deg);
}

.circleofsupport-wrapper .deg-24 {
  transform: rotate(24deg) translate(251px) rotate(-24deg);
}

.circleofsupport-wrapper .deg-25 {
  transform: rotate(25deg) translate(251px) rotate(-25deg);
}

.circleofsupport-wrapper .deg-26 {
  transform: rotate(26deg) translate(251px) rotate(-26deg);
}

.circleofsupport-wrapper .deg-27 {
  transform: rotate(27deg) translate(251px) rotate(-27deg);
}

.circleofsupport-wrapper .deg-28 {
  transform: rotate(28deg) translate(251px) rotate(-28deg);
}

.circleofsupport-wrapper .deg-29 {
  transform: rotate(29deg) translate(251px) rotate(-29deg);
}

.circleofsupport-wrapper .deg-30 {
  transform: rotate(30deg) translate(251px) rotate(-30deg);
}

.circleofsupport-wrapper .deg-31 {
  transform: rotate(31deg) translate(251px) rotate(-31deg);
}

.circleofsupport-wrapper .deg-32 {
  transform: rotate(32deg) translate(251px) rotate(-32deg);
}

.circleofsupport-wrapper .deg-33 {
  transform: rotate(33deg) translate(251px) rotate(-33deg);
}

.circleofsupport-wrapper .deg-34 {
  transform: rotate(34deg) translate(251px) rotate(-34deg);
}

.circleofsupport-wrapper .deg-35 {
  transform: rotate(35deg) translate(251px) rotate(-35deg);
}

.circleofsupport-wrapper .deg-36 {
  transform: rotate(36deg) translate(251px) rotate(-36deg);
}

.circleofsupport-wrapper .deg-37 {
  transform: rotate(37deg) translate(251px) rotate(-37deg);
}

.circleofsupport-wrapper .deg-38 {
  transform: rotate(38deg) translate(251px) rotate(-38deg);
}

.circleofsupport-wrapper .deg-39 {
  transform: rotate(39deg) translate(251px) rotate(-39deg);
}

.circleofsupport-wrapper .deg-40 {
  transform: rotate(40deg) translate(251px) rotate(-40deg);
}

.circleofsupport-wrapper .deg-41 {
  transform: rotate(41deg) translate(251px) rotate(-41deg);
}

.circleofsupport-wrapper .deg-42 {
  transform: rotate(42deg) translate(251px) rotate(-42deg);
}

.circleofsupport-wrapper .deg-43 {
  transform: rotate(43deg) translate(251px) rotate(-43deg);
}

.circleofsupport-wrapper .deg-44 {
  transform: rotate(44deg) translate(251px) rotate(-44deg);
}

.circleofsupport-wrapper .deg-45 {
  transform: rotate(45deg) translate(251px) rotate(-45deg);
}

.circleofsupport-wrapper .deg-46 {
  transform: rotate(46deg) translate(251px) rotate(-46deg);
}

.circleofsupport-wrapper .deg-47 {
  transform: rotate(47deg) translate(251px) rotate(-47deg);
}

.circleofsupport-wrapper .deg-48 {
  transform: rotate(48deg) translate(251px) rotate(-48deg);
}

.circleofsupport-wrapper .deg-49 {
  transform: rotate(49deg) translate(251px) rotate(-49deg);
}

.circleofsupport-wrapper .deg-50 {
  transform: rotate(50deg) translate(251px) rotate(-50deg);
}

.circleofsupport-wrapper .deg-51 {
  transform: rotate(51deg) translate(251px) rotate(-51deg);
}

.circleofsupport-wrapper .deg-52 {
  transform: rotate(52deg) translate(251px) rotate(-52deg);
}

.circleofsupport-wrapper .deg-53 {
  transform: rotate(53deg) translate(251px) rotate(-53deg);
}

.circleofsupport-wrapper .deg-54 {
  transform: rotate(54deg) translate(251px) rotate(-54deg);
}

.circleofsupport-wrapper .deg-55 {
  transform: rotate(55deg) translate(251px) rotate(-55deg);
}

.circleofsupport-wrapper .deg-56 {
  transform: rotate(56deg) translate(251px) rotate(-56deg);
}

.circleofsupport-wrapper .deg-57 {
  transform: rotate(57deg) translate(251px) rotate(-57deg);
}

.circleofsupport-wrapper .deg-58 {
  transform: rotate(58deg) translate(251px) rotate(-58deg);
}

.circleofsupport-wrapper .deg-59 {
  transform: rotate(59deg) translate(251px) rotate(-59deg);
}

.circleofsupport-wrapper .deg-60 {
  transform: rotate(60deg) translate(251px) rotate(-60deg);
}

.circleofsupport-wrapper .deg-61 {
  transform: rotate(61deg) translate(251px) rotate(-61deg);
}

.circleofsupport-wrapper .deg-62 {
  transform: rotate(62deg) translate(251px) rotate(-62deg);
}

.circleofsupport-wrapper .deg-63 {
  transform: rotate(63deg) translate(251px) rotate(-63deg);
}

.circleofsupport-wrapper .deg-64 {
  transform: rotate(64deg) translate(251px) rotate(-64deg);
}

.circleofsupport-wrapper .deg-65 {
  transform: rotate(65deg) translate(251px) rotate(-65deg);
}

.circleofsupport-wrapper .deg-66 {
  transform: rotate(66deg) translate(251px) rotate(-66deg);
}

.circleofsupport-wrapper .deg-67 {
  transform: rotate(67deg) translate(251px) rotate(-67deg);
}

.circleofsupport-wrapper .deg-68 {
  transform: rotate(68deg) translate(251px) rotate(-68deg);
}

.circleofsupport-wrapper .deg-69 {
  transform: rotate(69deg) translate(251px) rotate(-69deg);
}

.circleofsupport-wrapper .deg-70 {
  transform: rotate(70deg) translate(251px) rotate(-70deg);
}

.circleofsupport-wrapper .deg-71 {
  transform: rotate(71deg) translate(251px) rotate(-71deg);
}

.circleofsupport-wrapper .deg-72 {
  transform: rotate(72deg) translate(251px) rotate(-72deg);
}

.circleofsupport-wrapper .deg-73 {
  transform: rotate(73deg) translate(251px) rotate(-73deg);
}

.circleofsupport-wrapper .deg-74 {
  transform: rotate(74deg) translate(251px) rotate(-74deg);
}

.circleofsupport-wrapper .deg-75 {
  transform: rotate(75deg) translate(251px) rotate(-75deg);
}

.circleofsupport-wrapper .deg-76 {
  transform: rotate(76deg) translate(251px) rotate(-76deg);
}

.circleofsupport-wrapper .deg-77 {
  transform: rotate(77deg) translate(251px) rotate(-77deg);
}

.circleofsupport-wrapper .deg-78 {
  transform: rotate(78deg) translate(251px) rotate(-78deg);
}

.circleofsupport-wrapper .deg-79 {
  transform: rotate(79deg) translate(251px) rotate(-79deg);
}

.circleofsupport-wrapper .deg-80 {
  transform: rotate(80deg) translate(251px) rotate(-80deg);
}

.circleofsupport-wrapper .deg-81 {
  transform: rotate(81deg) translate(251px) rotate(-81deg);
}

.circleofsupport-wrapper .deg-82 {
  transform: rotate(82deg) translate(251px) rotate(-82deg);
}

.circleofsupport-wrapper .deg-83 {
  transform: rotate(83deg) translate(251px) rotate(-83deg);
}

.circleofsupport-wrapper .deg-84 {
  transform: rotate(84deg) translate(251px) rotate(-84deg);
}

.circleofsupport-wrapper .deg-85 {
  transform: rotate(85deg) translate(251px) rotate(-85deg);
}

.circleofsupport-wrapper .deg-86 {
  transform: rotate(86deg) translate(251px) rotate(-86deg);
}

.circleofsupport-wrapper .deg-87 {
  transform: rotate(87deg) translate(251px) rotate(-87deg);
}

.circleofsupport-wrapper .deg-88 {
  transform: rotate(88deg) translate(251px) rotate(-88deg);
}

.circleofsupport-wrapper .deg-89 {
  transform: rotate(89deg) translate(251px) rotate(-89deg);
}

.circleofsupport-wrapper .deg-90 {
  transform: rotate(90deg) translate(251px) rotate(-90deg);
}

.circleofsupport-wrapper .deg-91 {
  transform: rotate(91deg) translate(251px) rotate(-91deg);
}

.circleofsupport-wrapper .deg-92 {
  transform: rotate(92deg) translate(251px) rotate(-92deg);
}

.circleofsupport-wrapper .deg-93 {
  transform: rotate(93deg) translate(251px) rotate(-93deg);
}

.circleofsupport-wrapper .deg-94 {
  transform: rotate(94deg) translate(251px) rotate(-94deg);
}

.circleofsupport-wrapper .deg-95 {
  transform: rotate(95deg) translate(251px) rotate(-95deg);
}

.circleofsupport-wrapper .deg-96 {
  transform: rotate(96deg) translate(251px) rotate(-96deg);
}

.circleofsupport-wrapper .deg-97 {
  transform: rotate(97deg) translate(251px) rotate(-97deg);
}

.circleofsupport-wrapper .deg-98 {
  transform: rotate(98deg) translate(251px) rotate(-98deg);
}

.circleofsupport-wrapper .deg-99 {
  transform: rotate(99deg) translate(251px) rotate(-99deg);
}

.circleofsupport-wrapper .deg-100 {
  transform: rotate(100deg) translate(251px) rotate(-100deg);
}

.circleofsupport-wrapper .deg-101 {
  transform: rotate(101deg) translate(251px) rotate(-101deg);
}

.circleofsupport-wrapper .deg-102 {
  transform: rotate(102deg) translate(251px) rotate(-102deg);
}

.circleofsupport-wrapper .deg-103 {
  transform: rotate(103deg) translate(251px) rotate(-103deg);
}

.circleofsupport-wrapper .deg-104 {
  transform: rotate(104deg) translate(251px) rotate(-104deg);
}

.circleofsupport-wrapper .deg-105 {
  transform: rotate(105deg) translate(251px) rotate(-105deg);
}

.circleofsupport-wrapper .deg-106 {
  transform: rotate(106deg) translate(251px) rotate(-106deg);
}

.circleofsupport-wrapper .deg-107 {
  transform: rotate(107deg) translate(251px) rotate(-107deg);
}

.circleofsupport-wrapper .deg-108 {
  transform: rotate(108deg) translate(251px) rotate(-108deg);
}

.circleofsupport-wrapper .deg-109 {
  transform: rotate(109deg) translate(251px) rotate(-109deg);
}

.circleofsupport-wrapper .deg-110 {
  transform: rotate(110deg) translate(251px) rotate(-110deg);
}

.circleofsupport-wrapper .deg-111 {
  transform: rotate(111deg) translate(251px) rotate(-111deg);
}

.circleofsupport-wrapper .deg-112 {
  transform: rotate(112deg) translate(251px) rotate(-112deg);
}

.circleofsupport-wrapper .deg-113 {
  transform: rotate(113deg) translate(251px) rotate(-113deg);
}

.circleofsupport-wrapper .deg-114 {
  transform: rotate(114deg) translate(251px) rotate(-114deg);
}

.circleofsupport-wrapper .deg-115 {
  transform: rotate(115deg) translate(251px) rotate(-115deg);
}

.circleofsupport-wrapper .deg-116 {
  transform: rotate(116deg) translate(251px) rotate(-116deg);
}

.circleofsupport-wrapper .deg-117 {
  transform: rotate(117deg) translate(251px) rotate(-117deg);
}

.circleofsupport-wrapper .deg-118 {
  transform: rotate(118deg) translate(251px) rotate(-118deg);
}

.circleofsupport-wrapper .deg-119 {
  transform: rotate(119deg) translate(251px) rotate(-119deg);
}

.circleofsupport-wrapper .deg-120 {
  transform: rotate(120deg) translate(251px) rotate(-120deg);
}

.circleofsupport-wrapper .deg-121 {
  transform: rotate(121deg) translate(251px) rotate(-121deg);
}

.circleofsupport-wrapper .deg-122 {
  transform: rotate(122deg) translate(251px) rotate(-122deg);
}

.circleofsupport-wrapper .deg-123 {
  transform: rotate(123deg) translate(251px) rotate(-123deg);
}

.circleofsupport-wrapper .deg-124 {
  transform: rotate(124deg) translate(251px) rotate(-124deg);
}

.circleofsupport-wrapper .deg-125 {
  transform: rotate(125deg) translate(251px) rotate(-125deg);
}

.circleofsupport-wrapper .deg-126 {
  transform: rotate(126deg) translate(251px) rotate(-126deg);
}

.circleofsupport-wrapper .deg-127 {
  transform: rotate(127deg) translate(251px) rotate(-127deg);
}

.circleofsupport-wrapper .deg-128 {
  transform: rotate(128deg) translate(251px) rotate(-128deg);
}

.circleofsupport-wrapper .deg-129 {
  transform: rotate(129deg) translate(251px) rotate(-129deg);
}

.circleofsupport-wrapper .deg-130 {
  transform: rotate(130deg) translate(251px) rotate(-130deg);
}

.circleofsupport-wrapper .deg-131 {
  transform: rotate(131deg) translate(251px) rotate(-131deg);
}

.circleofsupport-wrapper .deg-132 {
  transform: rotate(132deg) translate(251px) rotate(-132deg);
}

.circleofsupport-wrapper .deg-133 {
  transform: rotate(133deg) translate(251px) rotate(-133deg);
}

.circleofsupport-wrapper .deg-134 {
  transform: rotate(134deg) translate(251px) rotate(-134deg);
}

.circleofsupport-wrapper .deg-135 {
  transform: rotate(135deg) translate(251px) rotate(-135deg);
}

.circleofsupport-wrapper .deg-136 {
  transform: rotate(136deg) translate(251px) rotate(-136deg);
}

.circleofsupport-wrapper .deg-137 {
  transform: rotate(137deg) translate(251px) rotate(-137deg);
}

.circleofsupport-wrapper .deg-138 {
  transform: rotate(138deg) translate(251px) rotate(-138deg);
}

.circleofsupport-wrapper .deg-139 {
  transform: rotate(139deg) translate(251px) rotate(-139deg);
}

.circleofsupport-wrapper .deg-140 {
  transform: rotate(140deg) translate(251px) rotate(-140deg);
}

.circleofsupport-wrapper .deg-141 {
  transform: rotate(141deg) translate(251px) rotate(-141deg);
}

.circleofsupport-wrapper .deg-142 {
  transform: rotate(142deg) translate(251px) rotate(-142deg);
}

.circleofsupport-wrapper .deg-143 {
  transform: rotate(143deg) translate(251px) rotate(-143deg);
}

.circleofsupport-wrapper .deg-144 {
  transform: rotate(144deg) translate(251px) rotate(-144deg);
}

.circleofsupport-wrapper .deg-145 {
  transform: rotate(145deg) translate(251px) rotate(-145deg);
}

.circleofsupport-wrapper .deg-146 {
  transform: rotate(146deg) translate(251px) rotate(-146deg);
}

.circleofsupport-wrapper .deg-147 {
  transform: rotate(147deg) translate(251px) rotate(-147deg);
}

.circleofsupport-wrapper .deg-148 {
  transform: rotate(148deg) translate(251px) rotate(-148deg);
}

.circleofsupport-wrapper .deg-149 {
  transform: rotate(149deg) translate(251px) rotate(-149deg);
}

.circleofsupport-wrapper .deg-150 {
  transform: rotate(150deg) translate(251px) rotate(-150deg);
}

.circleofsupport-wrapper .deg-151 {
  transform: rotate(151deg) translate(251px) rotate(-151deg);
}

.circleofsupport-wrapper .deg-152 {
  transform: rotate(152deg) translate(251px) rotate(-152deg);
}

.circleofsupport-wrapper .deg-153 {
  transform: rotate(153deg) translate(251px) rotate(-153deg);
}

.circleofsupport-wrapper .deg-154 {
  transform: rotate(154deg) translate(251px) rotate(-154deg);
}

.circleofsupport-wrapper .deg-155 {
  transform: rotate(155deg) translate(251px) rotate(-155deg);
}

.circleofsupport-wrapper .deg-156 {
  transform: rotate(156deg) translate(251px) rotate(-156deg);
}

.circleofsupport-wrapper .deg-157 {
  transform: rotate(157deg) translate(251px) rotate(-157deg);
}

.circleofsupport-wrapper .deg-158 {
  transform: rotate(158deg) translate(251px) rotate(-158deg);
}

.circleofsupport-wrapper .deg-159 {
  transform: rotate(159deg) translate(251px) rotate(-159deg);
}

.circleofsupport-wrapper .deg-160 {
  transform: rotate(160deg) translate(251px) rotate(-160deg);
}

.circleofsupport-wrapper .deg-161 {
  transform: rotate(161deg) translate(251px) rotate(-161deg);
}

.circleofsupport-wrapper .deg-162 {
  transform: rotate(162deg) translate(251px) rotate(-162deg);
}

.circleofsupport-wrapper .deg-163 {
  transform: rotate(163deg) translate(251px) rotate(-163deg);
}

.circleofsupport-wrapper .deg-164 {
  transform: rotate(164deg) translate(251px) rotate(-164deg);
}

.circleofsupport-wrapper .deg-165 {
  transform: rotate(165deg) translate(251px) rotate(-165deg);
}

.circleofsupport-wrapper .deg-166 {
  transform: rotate(166deg) translate(251px) rotate(-166deg);
}

.circleofsupport-wrapper .deg-167 {
  transform: rotate(167deg) translate(251px) rotate(-167deg);
}

.circleofsupport-wrapper .deg-168 {
  transform: rotate(168deg) translate(251px) rotate(-168deg);
}

.circleofsupport-wrapper .deg-169 {
  transform: rotate(169deg) translate(251px) rotate(-169deg);
}

.circleofsupport-wrapper .deg-170 {
  transform: rotate(170deg) translate(251px) rotate(-170deg);
}

.circleofsupport-wrapper .deg-171 {
  transform: rotate(171deg) translate(251px) rotate(-171deg);
}

.circleofsupport-wrapper .deg-172 {
  transform: rotate(172deg) translate(251px) rotate(-172deg);
}

.circleofsupport-wrapper .deg-173 {
  transform: rotate(173deg) translate(251px) rotate(-173deg);
}

.circleofsupport-wrapper .deg-174 {
  transform: rotate(174deg) translate(251px) rotate(-174deg);
}

.circleofsupport-wrapper .deg-175 {
  transform: rotate(175deg) translate(251px) rotate(-175deg);
}

.circleofsupport-wrapper .deg-176 {
  transform: rotate(176deg) translate(251px) rotate(-176deg);
}

.circleofsupport-wrapper .deg-177 {
  transform: rotate(177deg) translate(251px) rotate(-177deg);
}

.circleofsupport-wrapper .deg-178 {
  transform: rotate(178deg) translate(251px) rotate(-178deg);
}

.circleofsupport-wrapper .deg-179 {
  transform: rotate(179deg) translate(251px) rotate(-179deg);
}

.circleofsupport-wrapper .deg-180 {
  transform: rotate(180deg) translate(251px) rotate(-180deg);
}

.circleofsupport-wrapper .deg-181 {
  transform: rotate(181deg) translate(251px) rotate(-181deg);
}

.circleofsupport-wrapper .deg-182 {
  transform: rotate(182deg) translate(251px) rotate(-182deg);
}

.circleofsupport-wrapper .deg-183 {
  transform: rotate(183deg) translate(251px) rotate(-183deg);
}

.circleofsupport-wrapper .deg-184 {
  transform: rotate(184deg) translate(251px) rotate(-184deg);
}

.circleofsupport-wrapper .deg-185 {
  transform: rotate(185deg) translate(251px) rotate(-185deg);
}

.circleofsupport-wrapper .deg-186 {
  transform: rotate(186deg) translate(251px) rotate(-186deg);
}

.circleofsupport-wrapper .deg-187 {
  transform: rotate(187deg) translate(251px) rotate(-187deg);
}

.circleofsupport-wrapper .deg-188 {
  transform: rotate(188deg) translate(251px) rotate(-188deg);
}

.circleofsupport-wrapper .deg-189 {
  transform: rotate(189deg) translate(251px) rotate(-189deg);
}

.circleofsupport-wrapper .deg-190 {
  transform: rotate(190deg) translate(251px) rotate(-190deg);
}

.circleofsupport-wrapper .deg-191 {
  transform: rotate(191deg) translate(251px) rotate(-191deg);
}

.circleofsupport-wrapper .deg-192 {
  transform: rotate(192deg) translate(251px) rotate(-192deg);
}

.circleofsupport-wrapper .deg-193 {
  transform: rotate(193deg) translate(251px) rotate(-193deg);
}

.circleofsupport-wrapper .deg-194 {
  transform: rotate(194deg) translate(251px) rotate(-194deg);
}

.circleofsupport-wrapper .deg-195 {
  transform: rotate(195deg) translate(251px) rotate(-195deg);
}

.circleofsupport-wrapper .deg-196 {
  transform: rotate(196deg) translate(251px) rotate(-196deg);
}

.circleofsupport-wrapper .deg-197 {
  transform: rotate(197deg) translate(251px) rotate(-197deg);
}

.circleofsupport-wrapper .deg-198 {
  transform: rotate(198deg) translate(251px) rotate(-198deg);
}

.circleofsupport-wrapper .deg-199 {
  transform: rotate(199deg) translate(251px) rotate(-199deg);
}

.circleofsupport-wrapper .deg-200 {
  transform: rotate(200deg) translate(251px) rotate(-200deg);
}

.circleofsupport-wrapper .deg-201 {
  transform: rotate(201deg) translate(251px) rotate(-201deg);
}

.circleofsupport-wrapper .deg-202 {
  transform: rotate(202deg) translate(251px) rotate(-202deg);
}

.circleofsupport-wrapper .deg-203 {
  transform: rotate(203deg) translate(251px) rotate(-203deg);
}

.circleofsupport-wrapper .deg-204 {
  transform: rotate(204deg) translate(251px) rotate(-204deg);
}

.circleofsupport-wrapper .deg-205 {
  transform: rotate(205deg) translate(251px) rotate(-205deg);
}

.circleofsupport-wrapper .deg-206 {
  transform: rotate(206deg) translate(251px) rotate(-206deg);
}

.circleofsupport-wrapper .deg-207 {
  transform: rotate(207deg) translate(251px) rotate(-207deg);
}

.circleofsupport-wrapper .deg-208 {
  transform: rotate(208deg) translate(251px) rotate(-208deg);
}

.circleofsupport-wrapper .deg-209 {
  transform: rotate(209deg) translate(251px) rotate(-209deg);
}

.circleofsupport-wrapper .deg-210 {
  transform: rotate(210deg) translate(251px) rotate(-210deg);
}

.circleofsupport-wrapper .deg-211 {
  transform: rotate(211deg) translate(251px) rotate(-211deg);
}

.circleofsupport-wrapper .deg-212 {
  transform: rotate(212deg) translate(251px) rotate(-212deg);
}

.circleofsupport-wrapper .deg-213 {
  transform: rotate(213deg) translate(251px) rotate(-213deg);
}

.circleofsupport-wrapper .deg-214 {
  transform: rotate(214deg) translate(251px) rotate(-214deg);
}

.circleofsupport-wrapper .deg-215 {
  transform: rotate(215deg) translate(251px) rotate(-215deg);
}

.circleofsupport-wrapper .deg-216 {
  transform: rotate(216deg) translate(251px) rotate(-216deg);
}

.circleofsupport-wrapper .deg-217 {
  transform: rotate(217deg) translate(251px) rotate(-217deg);
}

.circleofsupport-wrapper .deg-218 {
  transform: rotate(218deg) translate(251px) rotate(-218deg);
}

.circleofsupport-wrapper .deg-219 {
  transform: rotate(219deg) translate(251px) rotate(-219deg);
}

.circleofsupport-wrapper .deg-220 {
  transform: rotate(220deg) translate(251px) rotate(-220deg);
}

.circleofsupport-wrapper .deg-221 {
  transform: rotate(221deg) translate(251px) rotate(-221deg);
}

.circleofsupport-wrapper .deg-222 {
  transform: rotate(222deg) translate(251px) rotate(-222deg);
}

.circleofsupport-wrapper .deg-223 {
  transform: rotate(223deg) translate(251px) rotate(-223deg);
}

.circleofsupport-wrapper .deg-224 {
  transform: rotate(224deg) translate(251px) rotate(-224deg);
}

.circleofsupport-wrapper .deg-225 {
  transform: rotate(225deg) translate(251px) rotate(-225deg);
}

.circleofsupport-wrapper .deg-226 {
  transform: rotate(226deg) translate(251px) rotate(-226deg);
}

.circleofsupport-wrapper .deg-227 {
  transform: rotate(227deg) translate(251px) rotate(-227deg);
}

.circleofsupport-wrapper .deg-228 {
  transform: rotate(228deg) translate(251px) rotate(-228deg);
}

.circleofsupport-wrapper .deg-229 {
  transform: rotate(229deg) translate(251px) rotate(-229deg);
}

.circleofsupport-wrapper .deg-230 {
  transform: rotate(230deg) translate(251px) rotate(-230deg);
}

.circleofsupport-wrapper .deg-231 {
  transform: rotate(231deg) translate(251px) rotate(-231deg);
}

.circleofsupport-wrapper .deg-232 {
  transform: rotate(232deg) translate(251px) rotate(-232deg);
}

.circleofsupport-wrapper .deg-233 {
  transform: rotate(233deg) translate(251px) rotate(-233deg);
}

.circleofsupport-wrapper .deg-234 {
  transform: rotate(234deg) translate(251px) rotate(-234deg);
}

.circleofsupport-wrapper .deg-235 {
  transform: rotate(235deg) translate(251px) rotate(-235deg);
}

.circleofsupport-wrapper .deg-236 {
  transform: rotate(236deg) translate(251px) rotate(-236deg);
}

.circleofsupport-wrapper .deg-237 {
  transform: rotate(237deg) translate(251px) rotate(-237deg);
}

.circleofsupport-wrapper .deg-238 {
  transform: rotate(238deg) translate(251px) rotate(-238deg);
}

.circleofsupport-wrapper .deg-239 {
  transform: rotate(239deg) translate(251px) rotate(-239deg);
}

.circleofsupport-wrapper .deg-240 {
  transform: rotate(240deg) translate(251px) rotate(-240deg);
}

.circleofsupport-wrapper .deg-241 {
  transform: rotate(241deg) translate(251px) rotate(-241deg);
}

.circleofsupport-wrapper .deg-242 {
  transform: rotate(242deg) translate(251px) rotate(-242deg);
}

.circleofsupport-wrapper .deg-243 {
  transform: rotate(243deg) translate(251px) rotate(-243deg);
}

.circleofsupport-wrapper .deg-244 {
  transform: rotate(244deg) translate(251px) rotate(-244deg);
}

.circleofsupport-wrapper .deg-245 {
  transform: rotate(245deg) translate(251px) rotate(-245deg);
}

.circleofsupport-wrapper .deg-246 {
  transform: rotate(246deg) translate(251px) rotate(-246deg);
}

.circleofsupport-wrapper .deg-247 {
  transform: rotate(247deg) translate(251px) rotate(-247deg);
}

.circleofsupport-wrapper .deg-248 {
  transform: rotate(248deg) translate(251px) rotate(-248deg);
}

.circleofsupport-wrapper .deg-249 {
  transform: rotate(249deg) translate(251px) rotate(-249deg);
}

.circleofsupport-wrapper .deg-250 {
  transform: rotate(250deg) translate(251px) rotate(-250deg);
}

.circleofsupport-wrapper .deg-251 {
  transform: rotate(251deg) translate(251px) rotate(-251deg);
}

.circleofsupport-wrapper .deg-252 {
  transform: rotate(252deg) translate(251px) rotate(-252deg);
}

.circleofsupport-wrapper .deg-253 {
  transform: rotate(253deg) translate(251px) rotate(-253deg);
}

.circleofsupport-wrapper .deg-254 {
  transform: rotate(254deg) translate(251px) rotate(-254deg);
}

.circleofsupport-wrapper .deg-255 {
  transform: rotate(255deg) translate(251px) rotate(-255deg);
}

.circleofsupport-wrapper .deg-256 {
  transform: rotate(256deg) translate(251px) rotate(-256deg);
}

.circleofsupport-wrapper .deg-257 {
  transform: rotate(257deg) translate(251px) rotate(-257deg);
}

.circleofsupport-wrapper .deg-258 {
  transform: rotate(258deg) translate(251px) rotate(-258deg);
}

.circleofsupport-wrapper .deg-259 {
  transform: rotate(259deg) translate(251px) rotate(-259deg);
}

.circleofsupport-wrapper .deg-260 {
  transform: rotate(260deg) translate(251px) rotate(-260deg);
}

.circleofsupport-wrapper .deg-261 {
  transform: rotate(261deg) translate(251px) rotate(-261deg);
}

.circleofsupport-wrapper .deg-262 {
  transform: rotate(262deg) translate(251px) rotate(-262deg);
}

.circleofsupport-wrapper .deg-263 {
  transform: rotate(263deg) translate(251px) rotate(-263deg);
}

.circleofsupport-wrapper .deg-264 {
  transform: rotate(264deg) translate(251px) rotate(-264deg);
}

.circleofsupport-wrapper .deg-265 {
  transform: rotate(265deg) translate(251px) rotate(-265deg);
}

.circleofsupport-wrapper .deg-266 {
  transform: rotate(266deg) translate(251px) rotate(-266deg);
}

.circleofsupport-wrapper .deg-267 {
  transform: rotate(267deg) translate(251px) rotate(-267deg);
}

.circleofsupport-wrapper .deg-268 {
  transform: rotate(268deg) translate(251px) rotate(-268deg);
}

.circleofsupport-wrapper .deg-269 {
  transform: rotate(269deg) translate(251px) rotate(-269deg);
}

.circleofsupport-wrapper .deg-270 {
  transform: rotate(270deg) translate(251px) rotate(-270deg);
}

.circleofsupport-wrapper .deg-271 {
  transform: rotate(271deg) translate(251px) rotate(-271deg);
}

.circleofsupport-wrapper .deg-272 {
  transform: rotate(272deg) translate(251px) rotate(-272deg);
}

.circleofsupport-wrapper .deg-273 {
  transform: rotate(273deg) translate(251px) rotate(-273deg);
}

.circleofsupport-wrapper .deg-274 {
  transform: rotate(274deg) translate(251px) rotate(-274deg);
}

.circleofsupport-wrapper .deg-275 {
  transform: rotate(275deg) translate(251px) rotate(-275deg);
}

.circleofsupport-wrapper .deg-276 {
  transform: rotate(276deg) translate(251px) rotate(-276deg);
}

.circleofsupport-wrapper .deg-277 {
  transform: rotate(277deg) translate(251px) rotate(-277deg);
}

.circleofsupport-wrapper .deg-278 {
  transform: rotate(278deg) translate(251px) rotate(-278deg);
}

.circleofsupport-wrapper .deg-279 {
  transform: rotate(279deg) translate(251px) rotate(-279deg);
}

.circleofsupport-wrapper .deg-280 {
  transform: rotate(280deg) translate(251px) rotate(-280deg);
}

.circleofsupport-wrapper .deg-281 {
  transform: rotate(281deg) translate(251px) rotate(-281deg);
}

.circleofsupport-wrapper .deg-282 {
  transform: rotate(282deg) translate(251px) rotate(-282deg);
}

.circleofsupport-wrapper .deg-283 {
  transform: rotate(283deg) translate(251px) rotate(-283deg);
}

.circleofsupport-wrapper .deg-284 {
  transform: rotate(284deg) translate(251px) rotate(-284deg);
}

.circleofsupport-wrapper .deg-285 {
  transform: rotate(285deg) translate(251px) rotate(-285deg);
}

.circleofsupport-wrapper .deg-286 {
  transform: rotate(286deg) translate(251px) rotate(-286deg);
}

.circleofsupport-wrapper .deg-287 {
  transform: rotate(287deg) translate(251px) rotate(-287deg);
}

.circleofsupport-wrapper .deg-288 {
  transform: rotate(288deg) translate(251px) rotate(-288deg);
}

.circleofsupport-wrapper .deg-289 {
  transform: rotate(289deg) translate(251px) rotate(-289deg);
}

.circleofsupport-wrapper .deg-290 {
  transform: rotate(290deg) translate(251px) rotate(-290deg);
}

.circleofsupport-wrapper .deg-291 {
  transform: rotate(291deg) translate(251px) rotate(-291deg);
}

.circleofsupport-wrapper .deg-292 {
  transform: rotate(292deg) translate(251px) rotate(-292deg);
}

.circleofsupport-wrapper .deg-293 {
  transform: rotate(293deg) translate(251px) rotate(-293deg);
}

.circleofsupport-wrapper .deg-294 {
  transform: rotate(294deg) translate(251px) rotate(-294deg);
}

.circleofsupport-wrapper .deg-295 {
  transform: rotate(295deg) translate(251px) rotate(-295deg);
}

.circleofsupport-wrapper .deg-296 {
  transform: rotate(296deg) translate(251px) rotate(-296deg);
}

.circleofsupport-wrapper .deg-297 {
  transform: rotate(297deg) translate(251px) rotate(-297deg);
}

.circleofsupport-wrapper .deg-298 {
  transform: rotate(298deg) translate(251px) rotate(-298deg);
}

.circleofsupport-wrapper .deg-299 {
  transform: rotate(299deg) translate(251px) rotate(-299deg);
}

.circleofsupport-wrapper .deg-300 {
  transform: rotate(300deg) translate(251px) rotate(-300deg);
}

.circleofsupport-wrapper .deg-301 {
  transform: rotate(301deg) translate(251px) rotate(-301deg);
}

.circleofsupport-wrapper .deg-302 {
  transform: rotate(302deg) translate(251px) rotate(-302deg);
}

.circleofsupport-wrapper .deg-303 {
  transform: rotate(303deg) translate(251px) rotate(-303deg);
}

.circleofsupport-wrapper .deg-304 {
  transform: rotate(304deg) translate(251px) rotate(-304deg);
}

.circleofsupport-wrapper .deg-305 {
  transform: rotate(305deg) translate(251px) rotate(-305deg);
}

.circleofsupport-wrapper .deg-306 {
  transform: rotate(306deg) translate(251px) rotate(-306deg);
}

.circleofsupport-wrapper .deg-307 {
  transform: rotate(307deg) translate(251px) rotate(-307deg);
}

.circleofsupport-wrapper .deg-308 {
  transform: rotate(308deg) translate(251px) rotate(-308deg);
}

.circleofsupport-wrapper .deg-309 {
  transform: rotate(309deg) translate(251px) rotate(-309deg);
}

.circleofsupport-wrapper .deg-310 {
  transform: rotate(310deg) translate(251px) rotate(-310deg);
}

.circleofsupport-wrapper .deg-311 {
  transform: rotate(311deg) translate(251px) rotate(-311deg);
}

.circleofsupport-wrapper .deg-312 {
  transform: rotate(312deg) translate(251px) rotate(-312deg);
}

.circleofsupport-wrapper .deg-313 {
  transform: rotate(313deg) translate(251px) rotate(-313deg);
}

.circleofsupport-wrapper .deg-314 {
  transform: rotate(314deg) translate(251px) rotate(-314deg);
}

.circleofsupport-wrapper .deg-315 {
  transform: rotate(315deg) translate(251px) rotate(-315deg);
}

.circleofsupport-wrapper .deg-316 {
  transform: rotate(316deg) translate(251px) rotate(-316deg);
}

.circleofsupport-wrapper .deg-317 {
  transform: rotate(317deg) translate(251px) rotate(-317deg);
}

.circleofsupport-wrapper .deg-318 {
  transform: rotate(318deg) translate(251px) rotate(-318deg);
}

.circleofsupport-wrapper .deg-319 {
  transform: rotate(319deg) translate(251px) rotate(-319deg);
}

.circleofsupport-wrapper .deg-320 {
  transform: rotate(320deg) translate(251px) rotate(-320deg);
}

.circleofsupport-wrapper .deg-321 {
  transform: rotate(321deg) translate(251px) rotate(-321deg);
}

.circleofsupport-wrapper .deg-322 {
  transform: rotate(322deg) translate(251px) rotate(-322deg);
}

.circleofsupport-wrapper .deg-323 {
  transform: rotate(323deg) translate(251px) rotate(-323deg);
}

.circleofsupport-wrapper .deg-324 {
  transform: rotate(324deg) translate(251px) rotate(-324deg);
}

.circleofsupport-wrapper .deg-325 {
  transform: rotate(325deg) translate(251px) rotate(-325deg);
}

.circleofsupport-wrapper .deg-326 {
  transform: rotate(326deg) translate(251px) rotate(-326deg);
}

.circleofsupport-wrapper .deg-327 {
  transform: rotate(327deg) translate(251px) rotate(-327deg);
}

.circleofsupport-wrapper .deg-328 {
  transform: rotate(328deg) translate(251px) rotate(-328deg);
}

.circleofsupport-wrapper .deg-329 {
  transform: rotate(329deg) translate(251px) rotate(-329deg);
}

.circleofsupport-wrapper .deg-330 {
  transform: rotate(330deg) translate(251px) rotate(-330deg);
}

.circleofsupport-wrapper .deg-331 {
  transform: rotate(331deg) translate(251px) rotate(-331deg);
}

.circleofsupport-wrapper .deg-332 {
  transform: rotate(332deg) translate(251px) rotate(-332deg);
}

.circleofsupport-wrapper .deg-333 {
  transform: rotate(333deg) translate(251px) rotate(-333deg);
}

.circleofsupport-wrapper .deg-334 {
  transform: rotate(334deg) translate(251px) rotate(-334deg);
}

.circleofsupport-wrapper .deg-335 {
  transform: rotate(335deg) translate(251px) rotate(-335deg);
}

.circleofsupport-wrapper .deg-336 {
  transform: rotate(336deg) translate(251px) rotate(-336deg);
}

.circleofsupport-wrapper .deg-337 {
  transform: rotate(337deg) translate(251px) rotate(-337deg);
}

.circleofsupport-wrapper .deg-338 {
  transform: rotate(338deg) translate(251px) rotate(-338deg);
}

.circleofsupport-wrapper .deg-339 {
  transform: rotate(339deg) translate(251px) rotate(-339deg);
}

.circleofsupport-wrapper .deg-340 {
  transform: rotate(340deg) translate(251px) rotate(-340deg);
}

.circleofsupport-wrapper .deg-341 {
  transform: rotate(341deg) translate(251px) rotate(-341deg);
}

.circleofsupport-wrapper .deg-342 {
  transform: rotate(342deg) translate(251px) rotate(-342deg);
}

.circleofsupport-wrapper .deg-343 {
  transform: rotate(343deg) translate(251px) rotate(-343deg);
}

.circleofsupport-wrapper .deg-344 {
  transform: rotate(344deg) translate(251px) rotate(-344deg);
}

.circleofsupport-wrapper .deg-345 {
  transform: rotate(345deg) translate(251px) rotate(-345deg);
}

.circleofsupport-wrapper .deg-346 {
  transform: rotate(346deg) translate(251px) rotate(-346deg);
}

.circleofsupport-wrapper .deg-347 {
  transform: rotate(347deg) translate(251px) rotate(-347deg);
}

.circleofsupport-wrapper .deg-348 {
  transform: rotate(348deg) translate(251px) rotate(-348deg);
}

.circleofsupport-wrapper .deg-349 {
  transform: rotate(349deg) translate(251px) rotate(-349deg);
}

.circleofsupport-wrapper .deg-350 {
  transform: rotate(350deg) translate(251px) rotate(-350deg);
}

.circleofsupport-wrapper .deg-351 {
  transform: rotate(351deg) translate(251px) rotate(-351deg);
}

.circleofsupport-wrapper .deg-352 {
  transform: rotate(352deg) translate(251px) rotate(-352deg);
}

.circleofsupport-wrapper .deg-353 {
  transform: rotate(353deg) translate(251px) rotate(-353deg);
}

.circleofsupport-wrapper .deg-354 {
  transform: rotate(354deg) translate(251px) rotate(-354deg);
}

.circleofsupport-wrapper .deg-355 {
  transform: rotate(355deg) translate(251px) rotate(-355deg);
}

.circleofsupport-wrapper .deg-356 {
  transform: rotate(356deg) translate(251px) rotate(-356deg);
}

.circleofsupport-wrapper .deg-357 {
  transform: rotate(357deg) translate(251px) rotate(-357deg);
}

.circleofsupport-wrapper .deg-358 {
  transform: rotate(358deg) translate(251px) rotate(-358deg);
}

.circleofsupport-wrapper .deg-359 {
  transform: rotate(359deg) translate(251px) rotate(-359deg);
}
