.menu .dropdown-toggle::after {
    display: none;
}

.menu .nav-link {
    --bs-nav-link-font-size: 1rem;
}

.menu .navbar-nav {
    --bs-nav-link-padding-y: 0.5rem;
}

.menu .icon {
    font-size: 1.5rem;
}

.menu .nav-item .search {
    border-radius: 20px;
    background: var(--search-bg);
}

.sidebar-menu {
    transition: 0.5s;
}

@media only screen and (max-width: 991px) {
    .menu .navbar.w-100 {
        width: auto !important;
    }
    .sidebar-menu {
        position: fixed;
        width: 0%;
        overflow: hidden;
    }
    .sidebar-menu.open {
        width: 80%;
        margin: auto;
        left: 0;
        bottom: 0;
        top: 83px;
        z-index: 100;
    }
    .sidebar-menu .navbar-nav {
        width: 100%;
    }
    .nav-link {
        display: inline-block;
    }
}